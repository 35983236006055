import React from "react";

export const ContactUs: React.FC = () => {
    return (
        <section style={{backgroundColor: 'white', height: '720px', padding: '40px', paddingTop: '10px', width: '650px', margin: 'auto'}}>
            <div style={{direction: 'rtl', marginTop: '10px', textAlign: 'right'}} >
                <h2>ליצירת קשר</h2>
                <div>
                    <label>כתובת אימייל</label>&nbsp;&nbsp;
                    <a href="mailto:&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#119;&#097;&#118;&#101;&#115;&#111;&#102;&#116;&#119;&#097;&#114;&#101;&#046;&#110;&#101;&#116;">&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#119;&#097;&#118;&#101;&#115;&#111;&#102;&#116;&#119;&#097;&#114;&#101;&#046;&#110;&#101;&#116;</a>
                </div>
                <div>
                    <label>מספר טלפון (גיא)</label>&nbsp;&nbsp;
                    <a href="tel:&#048;&#053;&#052;&#045;&#052;&#052;&#052;&#051;&#048;&#052;&#050;">&#048;&#053;&#052;&#045;&#052;&#052;&#052;&#051;&#048;&#052;&#050;</a>
                </div>
            </div>
        </section>
    );
};