import React, {useEffect, useState} from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {Formik} from "formik";
import {FormikProps} from "formik/dist/types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slider from "@material-ui/core/Slider";
import {Meeting} from "../../types/meeting";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createMeeting, deleteMeeting, editMeeting, useFirebaseSetPage} from "../../services/firebaseUtils";
import {getTimeSlots, Timeslot} from "../../types/timeslot";
import TextField from "@material-ui/core/TextField";
import * as Yup from 'yup';

const LAST_DATE_SESSION_KEY = 'last-session-date';

const INITIAL_VALUE: Meeting = {
    displayName: '',
    date: new Date(),
    //startTime: new Date('01/01/01 8:00 AM'),
    //endTime: new Date('01/01/01 9:00 AM'),
    videoLink: '',
    description: '',
    timeSlot: 0,
    amountOfTimeslots: 1,
};

const schema = Yup.object().shape<Meeting>({
    amountOfTimeslots: Yup.number().required(),
    description: Yup.string().notRequired(),
    meetingId: Yup.string().notRequired(),
    date: Yup.date().required(),
    videoLink: Yup.string().notRequired(),
    hostDisplayName: Yup.string().notRequired(),
    timeSlot: Yup.number().required(),
    displayName: Yup.string().required()
});

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '400px', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const ManageMeeting: React.FC<{open: boolean, onClose: () => void, meeting?: Meeting}> = ({
    open,
    onClose,
    meeting,
}) => {
    const classes = useStyles();
    const isEditMode = !!meeting && !!meeting.meetingId && (meeting.meetingId !== '');
    const [timeslots, setTimeslots] = useState<Array<Timeslot>>([]);

    let initialValues: Meeting;
    if (!meeting) {
        const lastUsedDate = sessionStorage.getItem(LAST_DATE_SESSION_KEY);
        initialValues = INITIAL_VALUE;
        initialValues.date = lastUsedDate ? new Date(Date.parse(lastUsedDate)) : new Date();
    }
    else {
        const {displayName, timeSlot, hostDisplayName, videoLink, date, meetingId, description, amountOfTimeslots} = meeting;
        initialValues = {
            amountOfTimeslots,
            description: description ?? '',
            meetingId,
            date,
            videoLink,
            hostDisplayName,
            timeSlot,
            displayName,
        }
    }

    const onDelete = () => {
        const confirmed = window.confirm('למחוק את השיעור?');
        if (!confirmed) {
            return;
        }

        if (!!meeting) {
            deleteMeeting(meeting);
            onClose();
        }
    };

    useEffect(() => {
        (async () => {
            setTimeslots(await getTimeSlots());
        })();
    }, []);

    useFirebaseSetPage('Manage Meeting');

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" style={{direction: 'rtl'}}>
            {!isEditMode && <DialogTitle id="form-dialog-title" style={{textAlign: 'right'}}>הוספת שיעור</DialogTitle>}
            {isEditMode && <DialogTitle id="form-dialog-title">עריכת שיעור</DialogTitle>}

            <Formik
                onSubmit={(values, formikHelpers) => {
                    formikHelpers.setSubmitting(true);

                    if (isEditMode) {
                        editMeeting(values, meeting as Meeting);
                    }
                    else {
                        createMeeting(values);
                    }

                    sessionStorage.setItem(LAST_DATE_SESSION_KEY, values.date.toDateString());
                    onClose();
                }}
                initialValues={initialValues}
                validationSchema={schema}
                validateOnMount={true}
            >
                {
                    (formProps: FormikProps<Meeting>) =>
                        <>
                        <form onSubmit={formProps.handleSubmit} className={classes.form}>
                            <DialogContent>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TextField
                                        variant="standard"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="displayName"
                                        label="שם השיעור"
                                        name="displayName"
                                        autoComplete="displayName"
                                        value={formProps.values.displayName} onChange={formProps.handleChange} onBlur={formProps.handleBlur}
                                        autoFocus
                                    />

                                    <TextField
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                        id="hostDisplayName"
                                        label="שם המורה"
                                        name="hostDisplayName"
                                        autoComplete="hostDisplayName"
                                        value={formProps.values.hostDisplayName} onChange={formProps.handleChange} onBlur={formProps.handleBlur}
                                    />

                                    <TextField
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                        id="description"
                                        label="פירוט"
                                        name="description"
                                        multiline
                                        rows={2}
                                        value={formProps.values.description} onChange={formProps.handleChange} onBlur={formProps.handleBlur}
                                    />

                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date"
                                        label="תאריך"
                                        format="dd/MM/yyyy"
                                        value={formProps.values.date}
                                        onChange={(date) => {
                                            formProps.setFieldValue('date', date, true);
                                        }}
                                        onBlur={formProps.handleBlur}
                                        KeyboardButtonProps={{
                                            'aria-label': 'תאריך',
                                        }}
                                        fullWidth
                                    />

                                    <InputLabel htmlFor="timeSlot">שעה</InputLabel>
                                    <Select
                                        id="timeSlot"
                                        value={formProps.values.timeSlot}
                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                            formProps.setFieldValue('timeSlot', event.target.value, true);
                                        }}
                                        onBlur={formProps.handleBlur}
                                        fullWidth
                                    >
                                        {timeslots.map(item => (
                                            <MenuItem value={item.index}>{item.displayName}</MenuItem>
                                        ))}
                                    </Select>

                                    <Slider
                                        id="amountOfTimeslots"
                                        value={formProps.values.amountOfTimeslots}
                                        onChange={(event: object, value: number | number[]) => {
                                            formProps.setFieldValue('amountOfTimeslots', value, true);
                                        }}
                                        onBlur={formProps.handleBlur}
                                        aria-labelledby="amountOfTimeslots-label"
                                        step={1}
                                        min={1}
                                        max={2}
                                        valueLabelDisplay="auto"
                                        marks={[{value: 1, label: 'שיעור אחד'}, {value: 2, label: 'שני שיעורים'}]}
                                        style={{marginTop: '30px', marginRight: '30px', width: '85%'}}
                                    />

                                    <TextField
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                        id="videoLink"
                                        label="קישור וידאו"
                                        name="videoLink"
                                        type="url"
                                        style={{marginTop: '5px'}}
                                        value={formProps.values.videoLink} onChange={formProps.handleChange} onBlur={formProps.handleBlur}
                                    />
                                </MuiPickersUtilsProvider>
                            </DialogContent>
                            <DialogActions>
                                {isEditMode && (
                                    <Button onClick={onDelete} color="secondary">
                                        מחיקה
                                    </Button>
                                )}
                                <Button onClick={onClose} color="primary">
                                    ביטול
                                </Button>
                                <Button type={"submit"} color="primary" disabled={formProps.isSubmitting || !formProps.isValid}>
                                    שמירה
                                </Button>
                            </DialogActions>
                        </form>
                        </>
                }
            </Formik>
        </Dialog>
    );
};
