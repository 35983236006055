import React, {useEffect, useState} from "react";
import List from "@material-ui/core/List";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import ListItem from "@material-ui/core/ListItem";
import {FormControl, ListItemSecondaryAction} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import {getTimeSlots, Timeslot} from "../../types/timeslot";
import moment from "moment";
import Card from "@material-ui/core/Card";
import {Formik} from "formik";
import {FormikProps} from "formik/dist/types";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {createTimeslot, deleteTimeslot, useFirebaseSetPage} from "../../services/firebaseUtils";

const TIME_FORMAT = 'HH:mm';

const getInitialTime = () => {
    const initialTime = new Date();
    initialTime.setHours(8);
    initialTime.setMinutes(0);
    initialTime.setSeconds(0);
    initialTime.setMilliseconds(0);

    return initialTime;
};

const INITIAL_VALUES: Timeslot = {
    startTime: getInitialTime(),
    endTime: getInitialTime(),
    displayName: '',
    index: 0,
};

export const TimeslotsSettings: React.FC = () => {
    const [slots, setSlots] = useState<Array<Timeslot>>([]);

    const updateSlotsList = () => {
        (async () => {
            setSlots(await getTimeSlots());
        })();
    };

    useEffect(() => {
        updateSlotsList();
    }, []);

    useFirebaseSetPage('Timeslots Management');

    return (
        <>
            <h2>זמני שיעורים</h2>



            <List style={{width: '400px', margin: 'auto'}}>
                {slots.map(item => (
                    <ListItem key={item.startTime.toString()}>
                        <ListItemText>
                            {item.displayName}: {moment(item.startTime).format(TIME_FORMAT)}-{moment(item.endTime).format(TIME_FORMAT)}
                        </ListItemText>
                        {!!item.id && (
                            <ListItemSecondaryAction>
                                <Button
                                    color={'secondary'}
                                    onClick={() => {
                                        const confirmed = window.confirm('למחוק את השיעור?');

                                        if (confirmed && item.id) {
                                            deleteTimeslot(item.id)
                                            updateSlotsList();
                                        }
                                    }}
                                >
                                    מחיקה
                                </Button>
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                ))}
            </List>



            <Card variant={'outlined'} style={{paddingBottom: '20px'}}>
                <h3>הוספת שיעור</h3>
                <Formik
                    onSubmit={async (values, formikHelpers) => {
                        formikHelpers.setSubmitting(true);
                        await createTimeslot(values);
                        formikHelpers.setSubmitting(false);
                        formikHelpers.resetForm();
                        updateSlotsList();
                    }}
                    initialValues={INITIAL_VALUES}
                >
                    {
                        (formProps: FormikProps<Timeslot>) =>
                            <form onSubmit={formProps.handleSubmit}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container spacing={2} alignContent={"flex-start"}>
                                        <Grid item xs={12}>
                                            <FormControl>
                                                <InputLabel htmlFor="displayName">תיאור</InputLabel>
                                                <Input id="displayName" value={formProps.values.displayName}
                                                       onChange={formProps.handleChange} onBlur={formProps.handleBlur}
                                                       required fullWidth/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl>
                                                <KeyboardTimePicker
                                                    margin="normal"
                                                    id="startTime"
                                                    label="שעת התחלה"
                                                    value={formProps.values.startTime}
                                                    onChange={(date) => {
                                                        formProps.setFieldValue('startTime', date, true);
                                                    }}
                                                    onBlur={formProps.handleBlur}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl>
                                                <KeyboardTimePicker
                                                    margin="normal"
                                                    id="endTime"
                                                    label="שעת סיום"
                                                    value={formProps.values.endTime}
                                                    onChange={(date) => {
                                                        formProps.setFieldValue('endTime', date, true);
                                                    }}
                                                    onBlur={formProps.handleBlur}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </MuiPickersUtilsProvider>
                                <Button type={"submit"} disabled={!formProps.isValid || formProps.isSubmitting} color={"primary"} variant={"outlined"}>שמירה</Button>
                            </form>
                    }
                </Formik>
            </Card>


        </>
    );
};