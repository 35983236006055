import React from 'react';
import Button from "@material-ui/core/Button";
import {Formik} from "formik";
import {FormikProps} from "formik/dist/types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Meeting} from "../../types/meeting";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {editMeetingSummary, useFirebaseSetPage} from "../../services/firebaseUtils";
import TextField from "@material-ui/core/TextField";
import * as Yup from 'yup';
import {useLocation} from "react-router-dom";

interface FormModel {
    meetingId: string;
    summary?: string;
}

const schema = Yup.object().shape<FormModel>({
    meetingId: Yup.string().required(),
    summary: Yup.string().notRequired(),
});

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '400px', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const ManageMeetingSummary: React.FC<{open: boolean, onClose: () => void, meeting: Meeting}> = ({
    open,
    onClose,
    meeting,
}) => {
    const classes = useStyles();
    const location = useLocation();
    const calendarId = location.search.replace('?c=', '');
    const {meetingId = '', summary = '', displayName} = meeting;
    const initialValues: FormModel = {
        meetingId,
        summary,
    }

    useFirebaseSetPage('Manage Meeting Summary');

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" style={{direction: 'rtl'}}>
            <DialogTitle id="form-dialog-title" style={{textAlign: 'right'}}>סיכום שיעור</DialogTitle>

            <Formik
                onSubmit={(values, formikHelpers) => {
                    formikHelpers.setSubmitting(true);
                    editMeetingSummary(calendarId, meeting, values.summary ?? '');
                    onClose();
                }}
                initialValues={initialValues}
                validationSchema={schema}
                validateOnMount={true}
            >
                {
                    (formProps: FormikProps<FormModel>) =>
                        <>
                        <form onSubmit={formProps.handleSubmit} className={classes.form}>
                            <DialogContent>
                                <h2>
                                    {displayName}
                                </h2>

                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    id="summary"
                                    label="סיכום"
                                    name="summary"
                                    multiline
                                    rows={4}
                                    autoFocus
                                    value={formProps.values.summary} onChange={formProps.handleChange} onBlur={formProps.handleBlur}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onClose} color="primary">
                                    ביטול
                                </Button>
                                <Button type={"submit"} color="primary" disabled={formProps.isSubmitting || !formProps.isValid}>
                                    שמירה
                                </Button>
                            </DialogActions>
                        </form>
                        </>
                }
            </Formik>
        </Dialog>
    );
};
