import {copyLastWeek, isCopyLastWeekValid} from "../../../services/firebaseUtils";

export const useCopyLastWeek = () => async (firstDate: Date) => {
    const isValid = await isCopyLastWeekValid(firstDate);

    if (!isValid) {
        alert('לא ניתן להעתיק את השבוע הקודם משום שכבר נקבעו שיעורים לשבוע הנוכחי. אנא מחקו את השיעורים של השבוע הנוכחי ונסו שוב.');
        return;
    }

    await copyLastWeek(firstDate);
};