import React, {useEffect, useRef, useState} from 'react';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {firebaseLogEvent, getCalendarMeetingsByDate, useFirebaseSetPage} from "../../services/firebaseUtils";
import {Meeting, MeetingFullData} from "../../types/meeting";
import {getTimeSlots, Timeslot} from "../../types/timeslot";
import moment from "moment";
import {useLocation } from 'react-router-dom';
import {Switch} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Timer from "react-compound-timer";
import CircularProgress from "@material-ui/core/CircularProgress";
import {decreasedSecond} from "../../services/dateUtils";
import {getMeetingsFullData} from "../../services/meetings";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    button: {
        marginBottom: 15,
    },
});

const TIME_FORMAT = 'hh:mm a';
const UPDATE_VIEW_INTERVAL = 10000;

interface MeetingsToShow {
    current?: MeetingFullData;
    next?: MeetingFullData;
}

const getRoundedDate = () => {
    const now = new Date();
    now.setMilliseconds(0);
    now.setSeconds(0);

    return now;
};

const getMeetingsToShow = async (meetings: Array<Meeting>, calendarId: string): Promise<MeetingsToShow> => {
    const retVal: MeetingsToShow = {};
    const now = getRoundedDate();

    const timeslots: Array<Timeslot> = await getTimeSlots(calendarId);
    const meetingsFull: Array<MeetingFullData> = await getMeetingsFullData(meetings, timeslots);

    meetingsFull.forEach((meeting) => {
        const isStarted = meeting.startTime <= now;
        const isNotFinished: boolean = decreasedSecond(meeting.endTime) >= now;
        const isCurrent = isStarted && isNotFinished;
        const isSoonerThanNext = !retVal.next || (meeting.startTime < retVal.next.startTime);

        if (isCurrent) {
            retVal.current = meeting;
        }
        else if (isNotFinished && isSoonerThanNext) {
            retVal.next = meeting;
        }
    });

    return retVal;
};

const playSound = () => {
    try {
        const audio = new Audio('assets/sounds/school-bell.mp3');
        audio.play().catch(() => {});
    }
    catch (ex) {
        //do nothing
    }
};

export const LiveMeeting: React.FC = () => {
    const today = new Date();
    const classes = useStyles();
    const location = useLocation();
    const calendarId = location.search.replace('?c=', '');

    const [soundEnabled, setSoundEnabled] = useState(false);
    const [loading, setLoading] = useState(true);

    const [meetingsToShow, setMeetingsToShow] = useState<MeetingsToShow>({current: undefined, next: undefined});
    const meetingsToShowRef = useRef(meetingsToShow);
    meetingsToShowRef.current = meetingsToShow;

    const [meetings, setMeetings] = useState<Array<Meeting>>([]);
    const meetingsRef = useRef(meetings);
    meetingsRef.current = meetings;

    const updateDisplayedMeetings = async () => {
        if (!meetingsRef.current) {
            return;
        }

        const newMeetingstoShow = await getMeetingsToShow(meetingsRef.current, calendarId);
        setMeetingsToShow(newMeetingstoShow);
    };

    useEffect(() => {
        getCalendarMeetingsByDate(calendarId, new Date(), async (fetchedMeetings) => {
            setMeetings(fetchedMeetings);
            await updateDisplayedMeetings();
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            updateDisplayedMeetings();
        }, UPDATE_VIEW_INTERVAL);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const currentMeeting = meetingsToShow.current;

        if (!!currentMeeting &&
            !!currentMeeting.meeting.videoLink &&
            currentMeeting.meeting.videoLink !== '' &&
            moment(currentMeeting.startTime).add(1, 'minutes').toDate() > (new Date()) &&
            soundEnabled
        ) {
            const sessionKey = `already-played-${currentMeeting.meeting.meetingId}`;
            const wasAlreadyPlayed = sessionStorage.getItem(sessionKey);

            if (!wasAlreadyPlayed) {
                sessionStorage.setItem(sessionKey, 'a');
                playSound();
            }
        }
    }, [meetingsToShow]);

    useFirebaseSetPage('Live Meeting');

    const millisecondsBeforeMeeting = meetingsToShow.next ? meetingsToShow.next.startTime.getTime() - (new Date()).getTime() : 0;

    return (
        <>
            <Card>
                <FormControlLabel
                    value="top"
                    control={
                        <Switch
                            checked={soundEnabled}
                            onChange={(event) => setSoundEnabled(event.target.checked)}
                            value="checkedSound"
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label="צלצול"
                    labelPlacement="end"
                />
            </Card>

            <h3>
                {`${new Intl.DateTimeFormat('he-IL', { weekday: 'long' }).format(today)} ${today.getDate()}/${today.getMonth() + 1}`}
            </h3>

            {loading && <CircularProgress style={{marginTop: '20px'}} />}

            {!meetingsToShow.current && !loading && (
                <Card className={classes.root} variant={"outlined"}>
                    <CardContent>
                        <Typography color="textSecondary" style={{direction: 'rtl'}}>
                            כרגע אין שיעור - זמן חופשי!
                        </Typography>
                    </CardContent>
                </Card>
            )}
            {!!meetingsToShow.current && (
                <Card className={classes.root} variant={"outlined"}>
                    <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            כרגע
                        </Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom style={{fontWeight: 700}}>
                            {(meetingsToShow.current.meeting.amountOfTimeslots === 2) && (
                                <>(מסתיים ב-{moment(meetingsToShow.current.endTime).format('hh:mm')})&nbsp;</>
                            )}
                            {moment(meetingsToShow.current.startTime).format(TIME_FORMAT)}
                        </Typography>
                        <Typography variant="h5" component="h2" style={{color: '#D37F19', direction: 'rtl'}}>
                            {meetingsToShow.current.meeting.displayName}
                        </Typography>
                        {meetingsToShow.current.meeting.hostDisplayName && (
                            <Typography color="textSecondary">
                                {meetingsToShow.current.meeting.hostDisplayName}
                            </Typography>
                        )}
                        {meetingsToShow.current.meeting.description && (
                            <Typography color="textSecondary" style={{fontSize: '14px', direction: 'rtl'}}>
                                {meetingsToShow.current.meeting.description}
                            </Typography>
                        )}
                    </CardContent>
                    {meetingsToShow.current.meeting.videoLink && (
                        <CardActions style={{display: 'block'}} className={classes.button}>
                            <Button
                                size="small"
                                variant={"contained"}
                                color={"primary"}
                                href={meetingsToShow.current.meeting.videoLink}
                                target={'_blank'}
                                onClick={() => {
                                    firebaseLogEvent('open video', 'button', 'current meeting');
                                }}
                            >
                                פתיחה
                            </Button>
                        </CardActions>
                    )}
                </Card>
            )}

            {!!meetingsToShow.next && (
                <Card className={classes.root} variant={"outlined"}>
                    <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            השיעור הבא
                        </Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom style={{fontWeight: 700}}>
                            {(meetingsToShow.next.meeting.amountOfTimeslots === 2) && (
                                <>(מסתיים ב-{moment(meetingsToShow.next.endTime).format('hh:mm')})&nbsp;</>
                            )}
                            {moment(meetingsToShow.next.startTime).format(TIME_FORMAT)}
                        </Typography>
                        <Typography variant="h5" component="h2" style={{color: '#D37F19', direction: 'rtl'}}>
                            {meetingsToShow.next.meeting.displayName}
                        </Typography>
                        {meetingsToShow.next.meeting.hostDisplayName && (
                            <Typography color="textSecondary">
                                {meetingsToShow.next.meeting.hostDisplayName}
                            </Typography>
                        )}
                        {meetingsToShow.next.meeting.description && (
                            <Typography color="textSecondary" style={{fontSize: '14px', direction: 'rtl'}}>
                                {meetingsToShow.next.meeting.description}
                            </Typography>
                        )}
                    </CardContent>
                    {meetingsToShow.next.meeting.videoLink && (
                        <CardActions style={{display: 'block'}} className={classes.button}>
                            <Tooltip title="השיעור עוד לא התחיל">
                                <span>
                                <Button
                                    size="small"
                                    variant={"contained"}
                                    color={"primary"}
                                    disabled
                                >
                                    פתיחה
                                </Button>
                                </span>
                            </Tooltip>
                            { millisecondsBeforeMeeting > 0 && millisecondsBeforeMeeting <= (5 * 60 * 1000) && (
                                <div style={{fontSize: '12px', marginTop: '10px', direction: 'rtl'}}>
                                    עוד&nbsp;
                                    <Timer
                                        initialTime={millisecondsBeforeMeeting}
                                        direction={"backward"}
                                    >
                                        <Timer.Minutes />
                                        :
                                        <Timer.Seconds formatValue={value => value.toString().padStart(2, '0')} />
                                    </Timer>
                                    &nbsp;דקות
                                </div>
                            )}

                            <div style={{fontSize: '12px', marginTop: '15px'}}>
                                רוצים להתחבר לפני תחילת השיעור?
                                &nbsp;
                                <a
                                    href={meetingsToShow.next.meeting.videoLink}
                                    target="_blank"
                                    onClick={() => {
                                        firebaseLogEvent('open video', 'next meeting link', 'current meeting');
                                    }}
                                >
                                    לחצו כאן
                                </a>
                            </div>
                        </CardActions>
                    )}
                </Card>
            )}

            <div>
                <img
                    src="assets/kid_with_laptop.jpg"
                    width={200}
                    alt="kids with laptop"
                    style={{display: 'block', margin: 'auto', marginTop: '20px'}} />
{/*                <Snackbar
                    open={showCredit}
                    autoHideDuration={20000}
                    onClose={() => setShowCredit(false)}
                    style={{direction: 'rtl'}}
                    message="מוקדש לאהובות שלי: מיכל, שי-לי, שיר וליבי :)"
                />*/}

                <a href='https://www.freepik.com/vectors/school' style={{fontSize: '10px'}}>School vector created by studiogstock - www.freepik.com</a>
            </div>
        </>
    );
};
