import React, {useState} from 'react';
import {LiveMeeting} from "../../components/LiveMeeting";
import {DailyMeetings} from "../../components/DailyMeetings";
import EventNoteIcon from '@material-ui/icons/EventNote';
import MenuBook from '@material-ui/icons/MenuBook';
import Today from '@material-ui/icons/Today';
import {ToggleButton} from "@material-ui/lab";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {DailySummary} from "../../components/DailySummary";
import {useFirebaseSetPage} from "../../services/firebaseUtils";

enum DisplayMode {
    Current,
    EntireDay,
    Week,
    Summary
}

interface KeyValuedAlreadyLoaded {
    [key: string]: boolean;
}

const MODE_STORAGE_KEY = 'meeting-display-mode';

export const LiveView: React.FC = () => {
    const savedMode = localStorage[MODE_STORAGE_KEY];
    const [displayMode, setDisplayMode] = useState<DisplayMode>(savedMode ? parseInt(savedMode) : DisplayMode.Current);
    const [alreadyLoaded, setAlreadyLoaded] = useState<KeyValuedAlreadyLoaded>({
        'EntireDay': displayMode === DisplayMode.EntireDay,
        'Current': displayMode === DisplayMode.Current,
        'Summary': displayMode === DisplayMode.Summary,
    });

    useFirebaseSetPage('Live Meetings');

    return (
        <>
            <ToggleButtonGroup
                exclusive
                aria-label="תצוגת שיעורים"
                onChange={(event, nextDisplayMode) => {
                    if (nextDisplayMode !== null) {
                        let tempAlreadyLoaded = {...alreadyLoaded};
                        tempAlreadyLoaded[DisplayMode[nextDisplayMode]] = true;
                        setAlreadyLoaded(tempAlreadyLoaded);

                        setDisplayMode(nextDisplayMode);

                        if (nextDisplayMode !== DisplayMode.Summary) {
                            localStorage[MODE_STORAGE_KEY] = nextDisplayMode;
                        }
                    }
                }}
                value={displayMode}
            >
{/*                <ToggleButton
                    value={DisplayMode.Week}
                    aria-label="מערכת שבועית"
                >
                    מערכת שבועית
                    <EventNoteIcon style={{marginLeft: '10px'}} />
                </ToggleButton>*/}
                <ToggleButton
                    value={DisplayMode.Summary}
                    aria-label="סיכום יום"
                >
                    סיכום יום
                    <MenuBook style={{marginLeft: '10px'}} />
                </ToggleButton>
                <ToggleButton
                    value={DisplayMode.EntireDay}
                    aria-label="מערכת יומית"
                >
                    מערכת יומית
                    <EventNoteIcon style={{marginLeft: '10px'}} />
                </ToggleButton>
                <ToggleButton
                    value={DisplayMode.Current}
                    aria-label="השיעור הקרוב"
                >
                    השיעור הקרוב
                    <Today style={{marginLeft: '10px'}} />
                </ToggleButton>
            </ToggleButtonGroup>

            <div style={{display: displayMode === DisplayMode.EntireDay ? 'block' : 'none'}}>
                {alreadyLoaded[DisplayMode[DisplayMode.EntireDay]] && <DailyMeetings />}
            </div>
            <div style={{display: displayMode === DisplayMode.Current ? 'block' : 'none'}}>
                {alreadyLoaded[DisplayMode[DisplayMode.Current]] && <LiveMeeting />}
            </div>
            <div style={{display: displayMode === DisplayMode.Summary ? 'block' : 'none'}}>
                {alreadyLoaded[DisplayMode[DisplayMode.Summary]] && <DailySummary />}
            </div>
            {/*<div style={{display: displayMode === DisplayMode.Week ? 'block' : 'none'}}><WeeklyMeetings /></div>*/}
        </>
    );
};