import React from "react";
import Paper from "@material-ui/core/Paper";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useFirebaseSetPage} from "../../services/firebaseUtils";
import {Button} from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(5),
                width: theme.spacing(32),
                height: theme.spacing(16),
            },
        },
    }),
);

export const Home: React.FC = () => {
    const classes = useStyles();
    useFirebaseSetPage('Home');

    return (
        <>
        <div style={{backgroundColor: 'white', height: '640px', paddingTop: '10px'}}>
            <div style={{direction: 'rtl', marginTop: '10px'}} >
                ברוכים הבאים ל-My Zoom Links!<br/>
                האפליקציה שתאפשר לכם לפרסם קישורים למערכת השעות בצורה נוחה ופשוטה.<br/>
                <br/>
                הכל באמצעות לינק אחד פשוט!<br/>
            </div>

            <div className={classes.root} style={{marginTop: '5px', width: '100%', height: '440px', overflow: 'hidden'}}>
                <div style={{overflowX: 'scroll', width: '1000px', display: 'flex', height: '450px'}}>
                    <div style={{height: '400px', flex: 1, marginLeft: '30px'}}>
                        <img src="assets/screenshots/current_class_with_link.PNG" height="100%" />
                    </div>
                    <div style={{height: '400px', flex: 1, marginLeft: '30px'}}>
                        <img src="assets/screenshots/daily_meetings.PNG" height="100%" />
                    </div>
                    <div style={{height: '400px', flex: 1, marginLeft: '30px'}}>
                        <img src="assets/screenshots/next_class_with_link.PNG" height="100%" />
                    </div>
                </div>
            </div>
        </div>

        <footer>
            <Toolbar dir={'rtl'}>
                <Button color="inherit" href={'./about'} style={{marginLeft: '50px'}}>
                    אודות
                </Button>
                <Button color="inherit" href={'./contact-us'} style={{marginLeft: '50px'}}>
                    צרו קשר
                </Button>
                <Button color="inherit" href={'./calendars-list'} style={{marginLeft: '50px'}}>
                    מערכת-שעות לדוגמה
                </Button>
            </Toolbar>
        </footer>
    </>
    );
};
