import React from 'react';
import {FormControl} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import Button from "@material-ui/core/Button";
import {Formik} from "formik";
import {FormikProps} from "formik/dist/types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {createCalendar, useFirebaseSetPage} from "../../services/firebaseUtils";

interface CreateCalendarFormProps {
    displayName: string;
}

const INITIAL_VALUE: CreateCalendarFormProps = {
    displayName: '',
};

export const CreateCalendar: React.FC<{open: boolean, onClose: () => void}> = ({
    open,
    onClose,
}) => {
    useFirebaseSetPage('Create Calendar');

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" style={{direction: 'rtl'}}>
            <DialogTitle id="form-dialog-title" style={{textAlign: 'right'}}>יצירת מערכת שעות</DialogTitle>

            <Formik
                isInitialValid={true}
                onSubmit={async (data, formikHelpers) => {
                    formikHelpers.setSubmitting(true);
                    await createCalendar(data.displayName);
                    onClose();
                }}
                initialValues={INITIAL_VALUE}
            >
                {
                    (formProps: FormikProps<CreateCalendarFormProps>) =>
                        <form onSubmit={formProps.handleSubmit}>
                            <DialogContent>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <FormControl>
                                        <InputLabel htmlFor="displayName">שם</InputLabel>
                                        <Input
                                            id="displayName"
                                            required
                                            autoFocus
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                        />
                                    </FormControl>
                                </MuiPickersUtilsProvider>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onClose} color="primary">
                                    ביטול
                                </Button>
                                <Button type={"submit"} color="primary" disabled={formProps.isSubmitting && !formProps.isValid}>
                                    הוספה
                                </Button>
                            </DialogActions>
                        </form>
                }
            </Formik>
        </Dialog>
    );
};
