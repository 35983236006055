import React, {useEffect} from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import {LoginView} from "./views/login";
import {ScheduleBoard} from "./components/ScheduleBoard";
import {PageHeader} from "./components/PageHeader";
import {Home} from "./views/home";
import {activateAnalytics} from "./services/firebaseUtils";
import {TimeslotsSettings} from "./components/TimeslotsSettings";
import {LiveView} from "./views/live";
import {ManageCalendars} from "./views/manage-calendars";
import {JoinCalendar} from "./views/join-calendar";
import {CalendarsList} from "./views/calendars-list";
import {About} from "./views/about";
import {ContactUs} from "./views/contact-us";

function App() {
  useEffect(() => {
    activateAnalytics();
  });

  return (
      <Router>
        <div className="App">
          <PageHeader />

          <Switch>
            <Route exact path="/">
              <Redirect to="/home"/>
            </Route>
            <Route path="/login">
              <LoginView/>
            </Route>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/schedule">
              <ScheduleBoard/>
            </Route>
            <Route path="/timeslots">
              <TimeslotsSettings />
            </Route>
            <Route path="/live">
              <LiveView />
            </Route>
            <Route path="/calendars">
              <ManageCalendars />
            </Route>
            <Route path="/join-calendar">
              <JoinCalendar />
            </Route>
            <Route path="/calendars-list">
              <CalendarsList />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact-us">
              <ContactUs />
            </Route>
          </Switch>
        </div>
      </Router>
  );
}

export default App;
