import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Card from "@material-ui/core/Card";
import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useLocation} from "react-router-dom";
import {Meeting, MeetingFullData} from "../../types/meeting";
import {firebaseLogEvent, getCalendarMeetingsByDateNew, useFirebaseSetPage} from "../../services/firebaseUtils";
import {getTimeSlots, Timeslot} from "../../types/timeslot";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getMeetingsFullData} from "../../services/meetings";

const TIME_FORMAT = 'hh:mm a';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    button: {
        marginBottom: 15,
    },
});

export const DailyMeetings: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();
    const calendarId = location.search.replace('?c=', '');

    const [loading, setLoading] = useState(true);
    const [meetings, setMeetings] = useState<Array<MeetingFullData>>([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [today, setToday] = useState(true);

    useEffect(() => {
        (async () => {
            const timeslots: Array<Timeslot> = await getTimeSlots(calendarId);
            let fetchedMeetings: Array<Meeting> = [];
            const maxDate = moment(new Date()).add(4, "days").toDate();
            let today = true;
            let result: Array<MeetingFullData> = [];

            for (let tempDate = new Date(); (!result || result.length === 0) && (tempDate <= maxDate); tempDate = moment(tempDate).add(1, "days").toDate()) {
                let shouldShowFetchedMeetings = false;
                fetchedMeetings = await getCalendarMeetingsByDateNew(calendarId, tempDate);

                if (fetchedMeetings && (fetchedMeetings.length > 0)) {
                    result = await getMeetingsFullData(fetchedMeetings, timeslots);

                    if (result && result.length > 0) {
                        const lastItem = result[result.length - 1];
                        const isAfterLastMeeting = lastItem.endTime < new Date();
                        shouldShowFetchedMeetings = !today || !isAfterLastMeeting;
                    }
                }

                if (!shouldShowFetchedMeetings) {
                    result = [];
                    today = false;
                }
                setSelectedDate(tempDate);
            }

            setMeetings(result);
            setToday(today);
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useFirebaseSetPage('Daily Meetings');

    return (
        <>
            <h3>
                {meetings.length !== 0 && !today && (
                    <>
                        יום הלימודים הבא:
                        &nbsp;
                    </>
                )}
                {`${new Intl.DateTimeFormat('he-IL', { weekday: 'long' }).format(selectedDate)} ${selectedDate.getDate()}/${selectedDate.getMonth() + 1}`}
            </h3>

            {loading && <CircularProgress style={{marginTop: '20px'}} />}

            {meetings.length === 0 && !loading && (
                <Card className={classes.root} variant={"outlined"}>
                    <CardContent>
                        <Typography color="textSecondary" style={{direction: 'rtl'}}>
                            אין שיעורים - זמן חופשי!
                        </Typography>
                    </CardContent>
                </Card>
            )}

            {meetings.map((item) => (
                <Card className={classes.root} variant={"outlined"} key={item.meeting.meetingId}>
                    <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom style={{fontWeight: 700}}>
                            {(item.meeting.amountOfTimeslots === 2) && (
                                <>(מסתיים ב-{moment(item.endTime).format('hh:mm')})&nbsp;</>
                            )}
                            {moment(item.startTime).format(TIME_FORMAT)}
                        </Typography>
                        <Typography variant="h5" component="h2" style={{color: '#D37F19', direction: 'rtl'}}>
                            {item.meeting.displayName}
                        </Typography>
                        {item.meeting.hostDisplayName && (
                            <Typography color="textSecondary">
                                {item.meeting.hostDisplayName}
                            </Typography>
                        )}
                        {item.meeting.description && (
                            <Typography color="textSecondary" style={{fontSize: '14px', direction: 'rtl'}}>
                                {item.meeting.description}
                            </Typography>
                        )}
                    </CardContent>
                    {today && item.meeting.videoLink && (
                        <CardActions style={{display: 'block'}} className={classes.button}>
                            <Button
                                size="small"
                                variant={"contained"}
                                color={"primary"}
                                href={item.meeting.videoLink}
                                target={'_blank'}
                                onClick={() => {
                                    firebaseLogEvent('open video', 'button', 'daily meetings');
                                }}
                            >
                                פתיחה
                            </Button>
                        </CardActions>
                    )}
                </Card>
            ))}
        </>
    );
};