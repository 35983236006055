import {Meeting, MeetingFullData} from "../types/meeting";
import {Timeslot} from "../types/timeslot";

export const getMeetingsFullData = async(meetings: Array<Meeting>, timeslots: Array<Timeslot>): Promise<Array<MeetingFullData>> => {
    const sortedMeetings = meetings.sort((a, b) => a.timeSlot > b.timeSlot ? 1 : (a.timeSlot < b.timeSlot ? -1 : 0));
    const result: Array<MeetingFullData> = [];

    sortedMeetings.forEach((meeting) => {
        const timeslotIndex = meeting.timeSlot;
        const timeslot1 = (timeslotIndex < timeslots.length) ? timeslots[timeslotIndex] : undefined;
        const timeslot2 = (meeting.amountOfTimeslots === 2) && ((timeslotIndex + 1) < timeslots.length) ? timeslots[timeslotIndex + 1] : undefined;

        if (timeslot1) {
            result.push({
                meeting,
                startTime: timeslot1.startTime,
                endTime: timeslot2 ? timeslot2.endTime : timeslot1.endTime,
            });
        }
    });

    return result;
};