import {getTimelots} from "../services/firebaseUtils";

export interface Timeslot {
    id?: string;
    index: number;
    displayName: string;
    startTime: Date;
    endTime: Date;
}

/*const getTimeslot = (timeslotIndex: number, displayName: string, startHour: number, startMinutes: number, endHours: number, endMinutes: number) => {
    const startDate = new Date();
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    startDate.setMinutes(startMinutes);
    startDate.setHours(startHour);

    const endDate = new Date();
    endDate.setSeconds(59);
    endDate.setMilliseconds(0);
    endDate.setMinutes(endMinutes);
    endDate.setHours(endHours);

    return {startTime: startDate, endTime: endDate, index: timeslotIndex, displayName: displayName};
}*/

export const getTimeSlots = async (calendarId?: string): Promise<Array<Timeslot>> => {
    const timeslots: Array<Timeslot> = await getTimelots(calendarId);

    timeslots.forEach((slot, index) => {
        const now = new Date();
        slot.startTime.setDate(now.getDate());
        slot.startTime.setMonth(now.getMonth());
        slot.startTime.setFullYear(now.getFullYear());

        slot.endTime.setDate(now.getDate());
        slot.endTime.setMonth(now.getMonth());
        slot.endTime.setFullYear(now.getFullYear());
    });

    const sortedSlots = timeslots.sort((a, b) => a.startTime < b.startTime ? -1 : (a.startTime > b.startTime ? 1 : 0));

    sortedSlots.forEach((slot, index) => {
        slot.index = index;
    });

    /*
    timeslots.push(getTimeslot(0, 'פתיחת יום', 8, 0, 8, 14));
    timeslots.push(getTimeslot(1, 'שעה ראשונה', 8, 15, 8, 59));
    timeslots.push(getTimeslot(2, 'שעה שניה', 9, 15, 9, 59));
    timeslots.push(getTimeslot(3, 'שעה שלישית', 10, 30, 11, 14));
    timeslots.push(getTimeslot(4, 'שעה רביעית', 11, 15, 11, 59));
    timeslots.push(getTimeslot(5, 'שעה חמישית', 12, 0, 12, 44));
    timeslots.push(getTimeslot(6, 'שעה שישית', 12, 45, 12, 59));*/

    return Promise.resolve(sortedSlots);
};