import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useLocation} from "react-router-dom";
import {Meeting} from "../../types/meeting";
import {fetchUserCalendars, getCalendarMeetingsByDate, useFirebaseSetPage} from "../../services/firebaseUtils";
import {getTimeSlots, Timeslot} from "../../types/timeslot";
import CircularProgress from "@material-ui/core/CircularProgress";
import {IconButton} from "@material-ui/core";
import AssignmentIcon from '@material-ui/icons/Assignment';
import {ManageMeetingSummary} from "../ManageMeetingSummary";
import moment from 'moment';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import {Calendar} from "../../types/calendar";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    button: {
        marginBottom: 15,
    },
});

interface MeetingFullData {
    meeting: Meeting;
    timeslot: Timeslot;
}

const getMeetingWithinTimeslot = (meetings: Array<Meeting>, slot?: Timeslot): MeetingFullData | undefined => {
    if (!slot) {
        return;
    }

    for (const meeting of meetings) {
        const isSameTimeslot = meeting.timeSlot === slot.index;

        if (isSameTimeslot) {
            return {meeting, timeslot: slot};
        }
    }

    return;
};

export const DailySummary: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();
    const calendarId = location.search.replace('?c=', '');

    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [meetings, setMeetings] = useState<Array<MeetingFullData>>([]);
    const [currentDate, setCurrentDate] = useState<Date>(new Date());

    const [manageMeetingVisible, setManageMeetingVisible] = useState(false);
    const [selectedMeeting, setSelectedMeeting] = useState<Meeting>({
        date: new Date(),
        displayName: '',
        timeSlot: 1,
        amountOfTimeslots: 1,
    });

    useEffect(() => {
        (async() => {
            const userCalendars: Array<Calendar> = await fetchUserCalendars();

            if (userCalendars) {
                const isUsersCalendar = userCalendars.findIndex(item => item.id === calendarId) >= 0;

                if (isUsersCalendar) {
                    setEditMode(true);
                }
            }
        })();
    }, []);

    const refreshMeetings = async () => {
        const timeslots: Array<Timeslot> = await getTimeSlots(calendarId);
        const items: Array<MeetingFullData> = [];

        await getCalendarMeetingsByDate(calendarId, currentDate, (fetchedMeetings) => {
            timeslots.forEach((timeslot) => {
                const meeting = getMeetingWithinTimeslot(fetchedMeetings, timeslot);

                if (meeting) {
                    items.push(meeting);
                }
            });

            setMeetings(items);
            setLoading(false);
        });
    };

    useEffect(() => {
        (async () => {
            refreshMeetings();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDate]);

    useFirebaseSetPage('Daily Summary');

    return (
        <>
            <h3>
                <IconButton aria-label="next day" onClick={() => {
                    setLoading(true);
                    setCurrentDate(currentDate => moment(currentDate).add(1, 'days').toDate());
                }}>
                    <ArrowBackIos fontSize="large" />
                </IconButton>
                {`${new Intl.DateTimeFormat('he-IL', { weekday: 'long' }).format(currentDate)} ${currentDate.getDate()}/${currentDate.getMonth() + 1}`}
                <IconButton aria-label="previous day" onClick={() => {
                    setLoading(true);
                    setCurrentDate(currentDate => moment(currentDate).add(-1, 'days').toDate());
                }}>
                    <ArrowForwardIos fontSize="large" />
                </IconButton>
            </h3>

            {loading && <CircularProgress style={{marginTop: '20px'}} />}

            <ManageMeetingSummary
                open={manageMeetingVisible}
                onClose={() => {
                    setManageMeetingVisible(false)
                    refreshMeetings();
                }}
                meeting={selectedMeeting}
            />

            {meetings.length === 0 && !loading && (
                <Card className={classes.root} variant={"outlined"}>
                    <CardContent>
                        <Typography color="textSecondary" style={{direction: 'rtl'}}>
                            אין שיעורים - זמן חופשי!
                        </Typography>
                    </CardContent>
                </Card>
            )}

            {!loading && meetings.map((item) => (
                <Card className={classes.root} variant={"outlined"} key={item.meeting.meetingId}>
                    <CardContent>
                        <Typography variant="h5" component="h2" style={{color: '#D37F19', direction: 'rtl'}}>
                            {item.meeting.displayName}
                        </Typography>
                        {item.meeting.summary && (
                            <Typography color="textSecondary" style={{fontSize: '14px', direction: 'rtl', marginTop: '10px'}}>
                                {item.meeting.summary}
                            </Typography>
                        )}
                        {editMode &&  (
                            <>
                                <br/>
                                <IconButton
                                    onClick={() => {
                                        setSelectedMeeting(item.meeting);
                                        setManageMeetingVisible(true);
                                    }}
                                    component="span"
                                >
                                    <AssignmentIcon />
                                </IconButton>
                            </>
                        )}
                    </CardContent>
                </Card>
            ))}
        </>
    );
};