import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {
    getCalendarDisplayName,
    getUserId
} from "../../services/firebaseUtils";
import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Link from "@material-ui/core/Link";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {SESSION_KEYS} from "../../types/constants";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

export const PageHeader: React.FC = () => {
    const location = useLocation();
    const [calendarName, setCalendarName] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const urlCalendarId = location.search.replace('?c=', '');
    const isLivePage = location.pathname === '/live';
    const classes = useStyles();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showContactDetails, setShowContactDetails] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setLoggedIn(getUserId() !== '');
    });

    useEffect(() => {
        let calendarId = sessionStorage[SESSION_KEYS.SELECTED_CALENDAR];

        if (urlCalendarId && (urlCalendarId !== '') && !urlCalendarId.includes('?') && !urlCalendarId.includes('&')) {
            calendarId = urlCalendarId;
        }

        if (calendarId) {
            getCalendarDisplayName((fetchedCalendarName) => {
                setCalendarName(fetchedCalendarName)
                document.title = `${fetchedCalendarName} - My Zoom Links`
            }, urlCalendarId);
        }
    }, [urlCalendarId]);

    const handleCloseMenu = () => setMenuAnchorEl(null);

    return (
        <>
            <AppBar position="static" style={{backgroundColor: '#20a9ea'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={event => setMenuAnchorEl(event.currentTarget)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography
                        variant="h6"
                        className={classes.title}
                        style={{cursor: isLivePage ? 'default' : 'pointer'}}
                    >
                        {isLivePage && (
                            <>My Zoom Links</>
                        )}
                        {!isLivePage && (
                            <Link href="/" underline={"none"} color={"inherit"}>
                                My Zoom Links
                            </Link>
                        )}
                    </Typography>
                    {!loggedIn && !isLivePage && <Button href="/login" color="inherit">כניסה למורים</Button>}
                    {loggedIn && <label>מחובר</label>}
                </Toolbar>
            </AppBar>
            {!!calendarName && (
                <p className="Calendar-name" style={{fontSize: '20px', fontWeight: 500}}>
                    {calendarName}
                </p>
            )}

            <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={handleCloseMenu}
            >
                {!loggedIn && <Link href="/login" underline={"none"} color={"inherit"}>
                    <MenuItem>למורים בלבד - התחברות</MenuItem>
                </Link>}
                {loggedIn && <Link href="/calendars" underline={"none"} color={"inherit"}>
                    <MenuItem>מערכת שעות</MenuItem>
                </Link>}

                <Link underline={"none"} color={"inherit"} onClick={() => setShowContactDetails(true)}>
                    <MenuItem>צרו קשר</MenuItem>
                </Link>
{/*
                {loggedIn && <Link href={`/home`} underline={"none"} color={"inherit"}>
                    <MenuItem onClick={() => firebaseLogout()}>יציאה</MenuItem>
                </Link>}
*/}
            </Menu>

            <Dialog open={showContactDetails} onClose={() => setShowContactDetails(false)} aria-labelledby="form-dialog-title" style={{direction: 'rtl'}}>
                <DialogTitle style={{textAlign: 'right'}}>לתמיכה ושאלות</DialogTitle>
                <DialogContent>
                    <div>
                        <label>כתובת אימייל</label>&nbsp;&nbsp;
                        <a href="mailto:&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#119;&#097;&#118;&#101;&#115;&#111;&#102;&#116;&#119;&#097;&#114;&#101;&#046;&#110;&#101;&#116;">&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#119;&#097;&#118;&#101;&#115;&#111;&#102;&#116;&#119;&#097;&#114;&#101;&#046;&#110;&#101;&#116;</a>
                    </div>
                    <div>
                        <label>מספר טלפון (גיא)</label>&nbsp;&nbsp;
                        <a href="tel:&#048;&#053;&#052;&#045;&#052;&#052;&#052;&#051;&#048;&#052;&#050;">&#048;&#053;&#052;&#045;&#052;&#052;&#052;&#051;&#048;&#052;&#050;</a>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowContactDetails(false)} color="primary">
                        סגירה
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};