import React, {useCallback, useEffect, useState} from 'react';
import {ManageMeeting} from "../ManageMeeting";
import Button from '@material-ui/core/Button';
import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddToQueue from '@material-ui/icons/AddToQueue';
import AccessAlarm from '@material-ui/icons/AccessAlarm';
import Edit from '@material-ui/icons/Edit';
import FileCopy from '@material-ui/icons/FileCopy';
import {Meeting} from "../../types/meeting";
import {Calendar} from "../../types/calendar";
import {getCalendar, getMeetingsByDate, isLoggedIn, useFirebaseSetPage} from "../../services/firebaseUtils";
import moment from 'moment';
import {getTimeSlots, Timeslot} from "../../types/timeslot";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ImportantDevices from '@material-ui/icons/ImportantDevices';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import CircularProgress from "@material-ui/core/CircularProgress";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {toShortDateString} from "../../services/dateUtils";
import {useCopyLastWeek} from "./hooks/useCopyLastWeek";
import {redirect, redirectToLogin} from "../../services/routing";

const TIME_FORMAT = 'HH:mm';
const FIRST_DATE_LAST_SET_SESSION_KEY = 'lastFirstDate';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const getMeeting = (meetings: Array<Meeting>, date: Date, timeslotIndex: number): Meeting | undefined => {
    return meetings.find((item) => {
        return item.date.getFullYear() === date.getFullYear() &&
            item.date.getMonth() === date.getMonth() &&
            item.date.getDate() === date.getDate() &&
            item.timeSlot === timeslotIndex;
    });
};

const getFirstDateOfTheWeek = () => {
    const today = new Date();
    return moment(today).add(-today.getDay(), 'days').toDate();
};

const getRows = async (meetings: Array<Meeting>, startDate: Date): Promise<Array<Array<Meeting | undefined>>> => {
  const rows: Array<Array<Meeting | undefined>> = [];
  const timeslots = await getTimeSlots();

  for (let timeslotIndex = 0; timeslotIndex < timeslots.length; timeslotIndex++) {
      rows[timeslotIndex] = [];

      for (let colIndex = 0; colIndex < 7; colIndex++) {
          if (timeslotIndex > 0) {
              const previousSlotMeeting = rows[timeslotIndex - 1][colIndex];
              const isPreviousSlotSpanned = previousSlotMeeting && previousSlotMeeting.amountOfTimeslots > 1;

              if (isPreviousSlotSpanned) {
                  continue;
              }
          }

          const date = moment(startDate).add(colIndex, 'days').toDate();

          let currentMeeting = getMeeting(meetings, date, timeslotIndex);
          if (!currentMeeting) {
              currentMeeting = {
                  displayName: '',
                  timeSlot: timeslotIndex,
                  amountOfTimeslots: 1,
                  hostDisplayName: '',
                  videoLink: '',
                  date: date,
              };
          }
          rows[timeslotIndex][colIndex] = currentMeeting;
      }
  }

  return rows;
};

export const ScheduleBoard: React.FC = () => {
    const classes = useStyles();
    const [manageMeetingVisible, setManageMeetingVisible] = useState(false);
    const [selectedMeeting, setSelectedMeeting] = useState<Meeting>();
    const [calendar, setCalendar] = useState<Calendar>();
    const [meetings, setMeetings] = useState<Array<Meeting>>([]);
    const [firstDate, setFirstDate] = useState<Date>(sessionStorage[FIRST_DATE_LAST_SET_SESSION_KEY] ? new Date(sessionStorage[FIRST_DATE_LAST_SET_SESSION_KEY]) : getFirstDateOfTheWeek());
    const [loadingCalendar, setLoadingCalendar] = useState(true);
    const [loadingMeetings, setLoadingMeetings] = useState(true);
    const [loadingRows, setLoadingRows] = useState(true);
    const [rows, setRows] = useState<Array<Array<Meeting | undefined>>>([]);
    const [timeslots, setTimeslots] = useState<Array<Timeslot>>([]);
    const copyLastWeek = useCopyLastWeek();

    if (!isLoggedIn()) {
        redirectToLogin();
    }

    const dayHasMeetings = (day: number): boolean => {
        return meetings.findIndex((item, index) => {
            return item.date.getDay() === day;
        }) >= 0;
    };

    const getLastDate = useCallback(() => {
        return moment(firstDate).add(6, 'days').toDate();
    }, [firstDate]);

    const updateCalendar = () => {
        (async () => {
            try {
                !calendar && setLoadingCalendar(true);
                setCalendar(await getCalendar());
            }
            finally {
                setLoadingCalendar(false);
            }
        })();
    };

    useEffect(updateCalendar, []);

    const updateMeetings = () => {
        (async () => {
            if (calendar) {
                !meetings && setLoadingMeetings(true);
                setTimeslots(await getTimeSlots());
                setMeetings(await getMeetingsByDate(firstDate, getLastDate()));
                setLoadingMeetings(false);
            }
        })();
    };

    useEffect(updateMeetings, [calendar, firstDate]);

    useEffect(() => {
        (async () => {
            !rows && setLoadingRows(true);
            setRows(await getRows(meetings, firstDate));
            setLoadingRows(false);
        })();
    }, [meetings]);

    useFirebaseSetPage('Schedule Board');

    return (
        <>
        {!!calendar && (
            <>
                <ButtonGroup variant="outlined" color="primary" style={{marginTop: '10px'}}>
                    <Button
                        onClick={() => setManageMeetingVisible(true)}
                        startIcon={<AddToQueue />}
                    >
                        הוספת שיעור
                    </Button>
                    <Button
                        onClick={() => {
                            (async () => {
                                setLoadingMeetings(true);
                                await copyLastWeek(firstDate);
                                updateMeetings();
                            })();
                        }}
                        startIcon={<FileCopyOutlinedIcon />}
                    >
                        העתקת השבוע הקודם
                    </Button>
                    <Button
                        href="/timeslots"
                        startIcon={<AccessAlarm />}
                    >
                        זמני שיעורים
                    </Button>
                    <Button
                        onClick={() => {
                            const savedId = sessionStorage.getItem('userId'); 

                            if (savedId) {
                                localStorage.setItem('userId', savedId);
                            }

                            redirect(`live?c=${calendar.id}`, true);
                        }}
                        startIcon={<ImportantDevices />}
                    >
                        מה שהתלמידים רואים
                    </Button>
                </ButtonGroup>


                <ManageMeeting
                    open={manageMeetingVisible}
                    onClose={() => {
                        setManageMeetingVisible(false)
                        setSelectedMeeting(undefined);
                        updateMeetings();
                    }}
                    meeting={selectedMeeting}
                />

                <h3>
                    <IconButton aria-label="next week" onClick={() => {
                        setLoadingRows(true);
                        setFirstDate((firstDate) => {
                            const nextDate = moment(firstDate).add(7, 'days').toDate();
                            sessionStorage[FIRST_DATE_LAST_SET_SESSION_KEY] = nextDate.toDateString();
                            return nextDate;
                        });
                    }}>
                        <ArrowBackIos fontSize="large" />
                    </IconButton>
                    {toShortDateString(getLastDate())}&nbsp;-&nbsp;{toShortDateString(firstDate)}
                    <IconButton aria-label="previous week" onClick={() => {
                        setLoadingRows(true);
                        setFirstDate((firstDate) => {
                            const nextDate = moment(firstDate).add(-7, 'days').toDate();
                            sessionStorage[FIRST_DATE_LAST_SET_SESSION_KEY] = nextDate.toDateString();
                            return nextDate;
                        });
                    }}>
                        <ArrowForwardIos fontSize="large" />
                    </IconButton>
                </h3>

                {(loadingCalendar || loadingMeetings || loadingRows) && <CircularProgress />}

                {!(loadingCalendar || loadingMeetings || loadingRows)  && (
                    <TableContainer component={Paper} unselectable={"off"} dir={'rtl'}>
                        <Table
                            className={classes.table}
                            aria-label="מערכת שעות"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">שעות</TableCell>
                                    <TableCell align="center">
                                        יום ראשון
                                        {false && !dayHasMeetings(0) && <Button startIcon={<FileCopy />}></Button>}
                                    </TableCell>
                                    <TableCell align="center">יום שני</TableCell>
                                    <TableCell align="center">יום שלישי</TableCell>
                                    <TableCell align="center">יום רביעי</TableCell>
                                    <TableCell align="center">יום חמישי</TableCell>
                                    <TableCell align="center">יום שישי</TableCell>
                                    <TableCell align="center">יום שבת</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow style ={ index % 2 ? { background : "lightgray" }:{ background : "white" }}
                                    >
                                        <TableCell component="th" scope="row" align="center" style={{fontWeight: "bolder"}}>
                                            {timeslots[index].displayName}
                                            <br/>
                                            {moment(timeslots[index].startTime).format(TIME_FORMAT)}-{moment(timeslots[index].endTime).format(TIME_FORMAT)}
                                        </TableCell>

                                        {row.map((meeting) => {
                                            if (!meeting || meeting.displayName === '') {
                                                return (
                                                    <TableCell align="center">
                                                        <Button
                                                            onClick={() => {
                                                                setSelectedMeeting(meeting);
                                                                setManageMeetingVisible(true);
                                                            }}
                                                            startIcon={<Edit/>}
                                                        >
                                                        </Button>
                                                    </TableCell>
                                                );
                                            } else {
                                                return (
                                                    <TableCell
                                                        align="center"
                                                        rowSpan={meeting.amountOfTimeslots}
                                                        style={meeting.amountOfTimeslots > 1 ? {background: 'linear-gradient(45deg, lightgray, white)'} : {}}
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                setSelectedMeeting(meeting);
                                                                setManageMeetingVisible(true);
                                                            }}
                                                            endIcon={<Edit style={{marginRight: '10px'}} />}
                                                        >
                                                            {meeting.displayName}
                                                        </Button>
                                                        <br/>
                                                        {/*<IconButton
                                                            onClick={() => {
                                                                setSelectedMeeting(meeting);
                                                                setManageMeetingVisible(true);
                                                            }}
                                                            component="span"
                                                        >
                                                            <AssignmentIcon />
                                                        </IconButton>*/}
                                                    </TableCell>
                                                );
                                            }
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </>
        )}
        </>
    );
};
