import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {getCalendareShareKey, useFirebaseSetPage} from "../../services/firebaseUtils";
import TextField from "@material-ui/core/TextField";
import {Calendar} from "../../types/calendar";
import CircularProgress from "@material-ui/core/CircularProgress";

export const ShareCalendar: React.FC<{open: boolean, onClose: () => void, calendar: Calendar}> = ({
    open,
    onClose,
    calendar,
}) => {
    const [loading, setLoading] = useState(true);
    const [linkToShare, setLinkToShare] = useState<string>('');

    const getShareLink = (shareKey: string) => {
        return `${window.location.protocol}//${window.location.host}/join-calendar/?sk=${shareKey}`;
    };

    useEffect(() => {
        (async () => {
            const shareKey = await getCalendareShareKey(calendar.id);
            setLinkToShare(getShareLink(shareKey));
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useFirebaseSetPage('Share Calendar');

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" style={{direction: 'rtl'}}>
            <DialogTitle id="form-dialog-title" style={{textAlign: 'right'}}>שיתוף מערכת שעות</DialogTitle>
            <form style={{width: '600px'}}>
                <DialogContent>
                    <h2>
                        {calendar.displayName}
                    </h2>

                    {loading && <CircularProgress style={{marginTop: '20px'}} />}

                    <TextField
                        value={linkToShare}
                        variant="standard"
                        margin="normal"
                        fullWidth
                        id="summary"
                        label="קישור לשיתוף מערכת השעות"
                        name="url"
                        autoFocus
                        contentEditable={false}
                        InputProps={{
                            style: {fontSize: '12px'},
                            readOnly: true,
                            onFocus: (event) => event.target.select(),
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        סגירה
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
