import React from "react";

export const About: React.FC = () => {
    return (
        <section style={{backgroundColor: 'white', height: '720px', padding: '40px', paddingTop: '10px', width: '650px', margin: 'auto'}}>
            <div style={{direction: 'rtl', marginTop: '10px', textAlign: 'right'}} >
                <p style={{direction: 'rtl'}}>מאז הופעת וירוס הקורונה, מליוני תלמידים ברחבי העולם נאלצים ללמוד מרחוק.<br />הלמידה מתבצעת בעיקר באמצעות שיחות וידאו.</p>
                <p style={{direction: 'rtl'}}>כך נוצר מצב, שהמורים נדרשים לפרסם קישורים לשיעורים השונים.<br />המתכונות הקיימות לפרסום מערכת-שעות - לא מותאמות למצב החדש.<br />התלמידים מקבלים מערכת-שעות במתכונת שאינה נוחה וקשה לתפעול.<br />לדוגמה: קבצי אקסל, הודעות ביישומי-מסרים כו'.</p>
                <p style={{direction: 'rtl'}}>פרסום מערכת-שעות באופן זה, טומנת בחובה מספר בעיות:</p>
                <ul>
                    <li style={{direction: 'rtl', textAlign: 'right'}}>קושי להפעיל את הקישורים לשיעורי-הוידאו.</li>
                    <li style={{direction: 'rtl', textAlign: 'right'}}>קושי לזהות בזמן את השיעור הנוכחי (לא כל השיעורים מתקיימים ברצף).</li>
                    <li style={{direction: 'rtl', textAlign: 'right'}}>לעתים קרובות יש צורך בשינוי הקישור, אז נדרש פרסום של השינוי בדרכים אחרות (למשל ב-WhatsApp).</li>
                    <li style={{direction: 'rtl', textAlign: 'right'}}>בכלל, המציאות החדשה גורמת לכך שמתבצעים הרבה שינויים תוך כדי תנועה.</li>
                </ul>
                <p style={{direction: 'rtl'}}>הפתרון המוצע כאן (My Zoom Links), פותר את כל הבעיות שמפורטות למעלה.<br />מאפשר גמישות רבה בהרכבת מערכת-שעות, הפרסום מתבצע מייד וניתן לבצע שינויים בזמן-אמת.<br />מבחינת התלמידים, היתרון הגדול הוא שהם מקבלים קישור אחד קבוע - באמצעותו הם יכולים לצפות במערכת שעות דיגיטלית, דרכה ניתן להתחבר בצורה נוחה לשיעורי-הוידאו.</p>
                <p style={{direction: 'rtl'}}>הדגש בבניית המערכת הוא נוחות מירבית - גם למורים וגם לתלמידים.</p>
                <p style={{direction: 'rtl'}}><br />ניתן ליצור עמי קשר (פרטים בלחיצה על התפריט שבראש הדף) לצורך תיאום תמיכה והתחלת עבודה.<br /></p>
                <p style={{direction: 'rtl'}}>&nbsp;</p>
                <p style={{direction: 'rtl'}}><a href={'./calendars-list'}>לחצו כאן לרשימת קישורים של מערכת-שעות לדוגמה</a></p>
                <p style={{direction: 'rtl'}}>&nbsp;</p>
                <p style={{direction: 'rtl'}}>בהצלחה!</p>
            </div>
        </section>
    );
};