import React, {useEffect} from 'react';
import './index.css';
import {firebaseLogin, firebaseLoginNoUI, useFirebaseSetPage} from "../../services/firebaseUtils";
import {useLocation} from "react-router-dom";
import {redirectToCalendars} from "../../services/routing";

function initLoginUI() {
    firebaseLogin('#firebaseui-auth-container');
}

export const LoginView: React.FC = () => {
    const location = useLocation();
    const urlParams = location.search.replace('?', '').split('&');

    useEffect(() => {
        initLoginUI();
    }, []);

    useFirebaseSetPage('Login');

    useEffect(() => {
        if (urlParams && urlParams.length > 1) {
            const username = urlParams[0].replace('username=', '');
            const password = urlParams[1].replace('password=', '');

            (async() => {
                await firebaseLoginNoUI(username, password);
                redirectToCalendars();
            })();
        }
    }, []);

    return (
        <>
            <div id="firebaseui-auth-container"/>
        </>
    );
};
