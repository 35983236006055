import React, {useEffect, useState} from 'react';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ListItemText} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {Calendar} from "../../types/calendar";
import {ShareCalendar} from "../../components/ShareCalendar";
import {fetchUserCalendars, isLoggedIn, signupToSharedCalendar, useFirebaseSetPage} from "../../services/firebaseUtils";
import {redirectToLogin, redirectToSchedule} from "../../services/routing";
import {SESSION_KEYS} from "../../types/constants";
import Button from "@material-ui/core/Button";
import {CreateCalendar} from "../../components/CreateCalendar";
import AddToQueue from "@material-ui/icons/AddToQueue";
import ButtonGroup from "@material-ui/core/ButtonGroup";

export const ManageCalendars: React.FC = () => {
    const [calendars, setCalendars] = useState<Array<Calendar>>([]);
    const [loading, setLoading] = useState(true);
    const [shareVisible, setShareVisible] = useState(false);
    const [selectedCalendar, setSelectedCalendar] = useState<Calendar>();
    const [openCreateCalendar, setOpenCreateCalendar] = useState(false);

    const fetchCalendars = async () => {
        const calendars = await fetchUserCalendars();
        setCalendars(calendars);
        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            const sharedCalendar = sessionStorage[SESSION_KEYS.SHARED_CALENDAR];

            if (sharedCalendar) {
                try {
                    await signupToSharedCalendar(sharedCalendar);
                }
                catch {
                    alert('הפעולה נכשלה, ככל הנראה הקישור החד-פעמי שקיבלת כבר נוצל.');
                }

                sessionStorage.removeItem(SESSION_KEYS.SHARED_CALENDAR);
                await fetchCalendars();
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await fetchCalendars();
        })();
    }, []);

    useFirebaseSetPage('Manage Calendars');

    if (!isLoggedIn()) {
        redirectToLogin();
        return <></>;
    }

    return (
        <>
            <ButtonGroup variant="outlined" color="primary" style={{marginTop: '10px'}}>
                <Button
                    onClick={() => setOpenCreateCalendar(true)}
                    startIcon={<AddToQueue />}
                >
                    יצירת מערכת-שעות
                </Button>
            </ButtonGroup>

            <Card style={{width: '700px', margin: 'auto', marginTop: '20px'}}>

                {loading && <CircularProgress style={{marginTop: '40px'}} />}

                {selectedCalendar && <ShareCalendar open={shareVisible} onClose={() => setShareVisible(false)} calendar={selectedCalendar} />}

                <CreateCalendar
                    open={openCreateCalendar}
                    onClose={() => {
                        fetchCalendars();
                        setOpenCreateCalendar(false);
                    }}
                />

                {(!calendars || (calendars.length === 0)) && !loading && (
                    <>
                        <div>
                            אין מערכת שעות פעילה
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{marginTop: '20px'}}
                            onClick={() => setOpenCreateCalendar(true)}
                        >
                            יצירת מערכת-שעות
                        </Button>
                    </>
                )}

                <List>
                    {calendars.map(item => (
                        <ListItem key={item.id}>
                            <ListItemAvatar>
                                <Avatar
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        sessionStorage[SESSION_KEYS.SELECTED_CALENDAR] = item.id;
                                        redirectToSchedule()
                                    }}
                                >
                                    <GroupIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    sessionStorage[SESSION_KEYS.SELECTED_CALENDAR] = item.id;
                                    redirectToSchedule()
                                }}
                                primary={`${item.displayName} ${item.isAdmin && item.usersCount && item.usersCount > 1 ? `(${item.usersCount} משתמשים)` : ''}`}
                            />
                            <ListItemSecondaryAction>
                                {item.isAdmin && (
                                    <IconButton
                                        edge="end"
                                        aria-label="share"
                                        style={{marginRight: '10px'}}
                                        onClick={() => {
                                            setSelectedCalendar(item);
                                            setShareVisible(true);
                                        }}
                                    >
                                        <GroupAddIcon />
                                    </IconButton>
                                )}
                                {/*<IconButton edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>*/}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Card>
            </>
    );
};