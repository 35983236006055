import firebase from "firebase";
import {getDateKey} from "./dateUtils";

export const getRefDateMeetings = (calendarId: string, date: Date) => {
    return firebase.database().ref(`data/calendars/${calendarId}/meetings/${getDateKey(date)}`);
}

export const getRefMeeting = (calendarId: string, date: Date, meetingId: string) => {
    const dateKey = getDateKey(date);
    return firebase.database().ref(`data/calendars/${calendarId}/meetings/${dateKey}/${meetingId}`);
}