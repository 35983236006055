import moment from "moment";

export const toShortDateString = (date: Date): string => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const getDateKey = (date: Date) => {
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
}

export const decreasedSecond = (date: Date) => {
    return moment(date).add(-1, 'seconds').toDate();
};