import React, {useEffect, useState} from 'react';
import {fetchAllCalendarIds, useFirebaseSetPage} from "../../services/firebaseUtils";
import Button from "@material-ui/core/Button";

export const CalendarsList: React.FC = () => {
  const [calendarIds, setCalendarIds] = useState<Array<string>>([]);

  useEffect(() => {
    (async() => {
      setCalendarIds(await fetchAllCalendarIds());
    })();
  }, []);

  useFirebaseSetPage('All Calendars List');

  return <>
          <h2>רשימת כל מערכות-השעות</h2>
          {calendarIds.map((item:string) => (
              <div>
                <a
                    href={`/live?c=${item}`}
                    target={'_blank'}
                >
                    {item}
                </a>
              </div>
          ))}
        </>;
};