export const redirect = (pageKey: string, newTab: boolean = false) => {
    const url = '/' + pageKey;

    if (newTab) {
        window.open(url, '_blank');
    }
    else {
        window.location.pathname = url;
    }
};

export const redirectToLogin = () => {
    redirect('login');
};

export const redirectToCalendars = () => {
    redirect('calendars');
};

export const redirectToSchedule = () => {
    redirect('schedule');
};