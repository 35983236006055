import React from 'react';
import {useLocation} from "react-router-dom";
import {SESSION_KEYS} from "../../types/constants";
import {redirectToCalendars} from "../../services/routing";
import {useFirebaseSetPage} from "../../services/firebaseUtils";

export const JoinCalendar: React.FC = () => {
  const location = useLocation();
  const urlShareKey = location.search.replace('?sk=', '');

  useFirebaseSetPage('Join Calendar');

  sessionStorage[SESSION_KEYS.SHARED_CALENDAR] = urlShareKey;
  redirectToCalendars();

  return <></>;
};