import firebase, {auth, analytics} from "firebase";
import "firebase/auth";
import {Meeting} from "../types/meeting";
import {Calendar} from "../types/calendar";
import UUID from 'uuidjs';
import moment from "moment/moment";
import {Timeslot} from "../types/timeslot";
import {getDateKey} from "./dateUtils";
import {getRefDateMeetings, getRefMeeting} from "./firebaseRefs";
import {SESSION_KEYS} from "../types/constants";
import {useEffect} from "react";

export const firebaseInit = () => {
    var firebaseConfig = {
        apiKey: "AIzaSyAw8C7dg54wnAlWutr_XlaYNVOWPSdUudA",
        authDomain: "my-zoom-links.firebaseapp.com",
        databaseURL: "https://my-zoom-links.firebaseio.com",
        projectId: "my-zoom-links",
        storageBucket: "my-zoom-links.appspot.com",
        messagingSenderId: "803875809601",
        appId: "1:803875809601:web:3d5a60910d7b025decd9c0",
        measurementId: "G-4SKYVMRRWG",
    };
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }
};

export const activateAnalytics = () => {
    firebaseInit();
    analytics();
}

export const firebaseLogin = (domObjectId: string) => {
    firebaseInit();
    var firebaseui = require('firebaseui');

    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    ui.start(domObjectId, {
        signInOptions: [
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: false,
            }
        ],
        signInFlow: 'popup',
        popupMode: true,
        signInSuccessUrl: '/calendars',
    });

    auth().onAuthStateChanged(() => {
        const user = auth().currentUser;

        if (user !== null) {
            sessionStorage.setItem('userId', user.uid);
        }
    });
};

export const firebaseLoginNoUI = async (username: string, password: string): Promise<void> => {
    firebaseInit();

    await auth().signInWithEmailAndPassword(username, password);
};

export const firebaseLogout = () => {
    firebaseInit();

    auth().signOut();
};

export const getCalendarMeetingsByDate = (calendarId: string, date: Date, callback: (meetings: Array<Meeting>) => void) => {
    firebaseInit();

    const dateKey = getDateKey(date);
    const path = `data/calendars/${calendarId}/meetings/${dateKey}`;

    const meetingsRef = firebase.database().ref(path);
    meetingsRef.on('value', function(snapshot) {
        const dbMeetings = snapshot.val();
        let meetings: Array<Meeting> = [];

        for (const meetingId in dbMeetings) {
            const dbItem = dbMeetings[meetingId];

            meetings.push({
                    amountOfTimeslots: dbItem.amountOfTimeslots,
                    displayName: dbItem.displayName,
                    hostDisplayName: dbItem.hostDisplayName,
                    description: dbItem.description,
                    date: new Date(Date.parse(dbItem.date)),
                    videoLink: dbItem.videoLink,
                    timeSlot: dbItem.timeSlot,
                    meetingId: meetingId,
                    summary: dbItem.summary,
                }
            );
        }

        callback(meetings);
    });
};

export const getCalendarMeetingsByDateNew = async (calendarId: string, date: Date): Promise<Array<Meeting>> => {
    firebaseInit();

    const dateKey = getDateKey(date);
    const path = `data/calendars/${calendarId}/meetings/${dateKey}`;

    let meetings: Array<Meeting> = [];

    const meetingsRef = firebase.database().ref(path);
    await meetingsRef.once('value', function(snapshot) {
        const dbMeetings = snapshot.val();

        for (const meetingId in dbMeetings) {
            const dbItem = dbMeetings[meetingId];

            meetings.push({
                    amountOfTimeslots: dbItem.amountOfTimeslots,
                    displayName: dbItem.displayName,
                    hostDisplayName: dbItem.hostDisplayName,
                    description: dbItem.description,
                    date: new Date(Date.parse(dbItem.date)),
                    videoLink: dbItem.videoLink,
                    timeSlot: dbItem.timeSlot,
                    meetingId: meetingId,
                    summary: dbItem.summary,
                }
            );
        }
    });

    return meetings;
};

export const getCalendarDisplayName = (callback: (displayName: string) => void, calendarId?: string) => {
    firebaseInit();

    getCalendarId(calendarId).then((tempCalendarId) => {
        const meetingsRef = firebase.database().ref(`data/calendars/${tempCalendarId}/general/displayName`);
        meetingsRef.once('value').then((snapshot) => {
            const displayName = snapshot.val();
            callback(displayName);
        });
    });
};

export const getUserId = (): string => {
    const result = sessionStorage.getItem('userId') || localStorage.getItem('userId') || '';
    localStorage.removeItem('userId');
    return result;
};

export const isLoggedIn = (): boolean => {
    return getUserId() !== '';
};

export const getCalendar = async (calendarId?: string): Promise<Calendar> => {
    firebaseInit();

    let calendarIdForDb = calendarId;

    if (!calendarIdForDb) {
        const selectedCalendarId = sessionStorage[SESSION_KEYS.SELECTED_CALENDAR];

        if (selectedCalendarId) {
            calendarIdForDb = selectedCalendarId;
        }
        else {
            const userId = getUserId();

            const userRef = firebase.database().ref(`data/users/${userId}/calendarId`);
            calendarIdForDb = await userRef.once('value').then((snapshot) => snapshot.val());
        }
    }

    const calendarsRef = firebase.database().ref(`data/calendars/${calendarIdForDb}/general`);
    const itemDb = await calendarsRef.once('value').then((snapshot) => snapshot.val());

    if (itemDb) {
        return Promise.resolve({
            displayName: itemDb.displayName,
            id: calendarIdForDb as string,
        });
    }

    return Promise.reject();
};

export const getMeetingsByDate = async (startDate: Date, endDate: Date, calendarId?: string): Promise<Array<Meeting>> => {
    firebaseInit();

    let tempCalendarId = await getCalendarId(calendarId);

    const allMeetings: Array<Meeting> = [];

    for (let tempDate = startDate; tempDate < endDate; tempDate = moment(tempDate).add(1, 'days').toDate()) {
        const meetingsRef = firebase.database().ref(`data/calendars/${tempCalendarId}/meetings/${getDateKey(tempDate)}`);
        const itemDb = await meetingsRef.once('value').then((snapshot) => snapshot.val());

        for (const meetingId in itemDb) {
            const tempMeeting = itemDb[meetingId];
            tempMeeting.date = new Date(Date.parse(tempMeeting.date));

            allMeetings.push(tempMeeting);
        }
    }

    return Promise.resolve(allMeetings);
};

export const getTimelots = async (calendarId?: string): Promise<Array<Timeslot>> => {
    firebaseInit();

    const calendar = await getCalendar(calendarId);

    const allSlots: Array<Timeslot> = [];

    const slotsRef = firebase.database().ref(`data/calendars/${calendar.id}/timeslots`);
    const itemDb = await slotsRef.once('value').then((snapshot) => snapshot.val());

    for (const slotId in itemDb) {
        const tempSlot: Timeslot = itemDb[slotId];
        tempSlot.startTime = new Date(Date.parse(itemDb[slotId].startTime));
        tempSlot.endTime = new Date(Date.parse(itemDb[slotId].endTime));
        tempSlot.id = slotId;

        allSlots.push(tempSlot);
    }

    return Promise.resolve(allSlots);
};

export const getGUID = () => {
    return UUID.genV4().toString();
};

const getDefaultTimeslots = () => {
    interface KeyValuedTimeslots {
        [key: string]: {
            id: string;
            displayName: string;
            startTime: string;
            endTime: string;
        };
    }

    const getTimeDate = (hours: number, minutes: number) => {
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date.toString();
    };

    const timeslots: KeyValuedTimeslots = {};

    let slotId = getGUID();
    timeslots[slotId] = {
        id: slotId,
        displayName: 'פתיחת בוקר',
        startTime: getTimeDate(8, 0),
        endTime: getTimeDate(8, 15),
    }
    slotId = getGUID();
    timeslots[slotId] = {
        id: slotId,
        displayName: 'שיעור ראשון',
        startTime: getTimeDate(8, 15),
        endTime: getTimeDate(9, 0),
    }
    slotId = getGUID();
    timeslots[slotId] = {
        id: slotId,
        displayName: 'שיעור שני',
        startTime: getTimeDate(9, 15),
        endTime: getTimeDate(10, 0),
    }
    slotId = getGUID();
    timeslots[slotId] = {
        id: slotId,
        displayName: 'הפסקת אוכל',
        startTime: getTimeDate(10, 0),
        endTime: getTimeDate(10, 30),
    }
    slotId = getGUID();
    timeslots[slotId] = {
        id: slotId,
        displayName: 'שיעור שלישי',
        startTime: getTimeDate(10, 30),
        endTime: getTimeDate(11, 15),
    }
    slotId = getGUID();
    timeslots[slotId] = {
        id: slotId,
        displayName: 'שיעור רביעי',
        startTime: getTimeDate(11, 15),
        endTime: getTimeDate(12, 0),
    }
    slotId = getGUID();
    timeslots[slotId] = {
        id: slotId,
        displayName: 'שיעור חמישי',
        startTime: getTimeDate(12, 0),
        endTime: getTimeDate(12, 45),
    }
    slotId = getGUID();
    timeslots[slotId] = {
        id: slotId,
        displayName: 'סיכום יום',
        startTime: getTimeDate(12, 45),
        endTime: getTimeDate(13, 0),
    }

    return timeslots;
};

export const createCalendar = async (displayName: string): Promise<any> => {
    firebaseInit();

    const userId = getUserId();
    const calendarId = getGUID();

    const usersRef = firebase.database().ref(`data/users/${userId}`);
    let user = await usersRef.once('value').then((snapshot) => snapshot.val());

    if (!user) {
        user = {
            license: 'NotPaying',
        };
    }

    if (!user.calendarId) {
        user.calendarId = calendarId;
    }

    if (!user.calendarIds) {
       user.calendarIds = [];
    }
    user.calendarIds.push(calendarId);

    await usersRef.set(user);

    const calendarRef = firebase.database().ref(`data/calendars/${calendarId}`);
    await calendarRef.set({
        general: {
            adminsIds: [userId],
            displayName: displayName,
            license: 'NotPaying',
        },
        meetings: {},
        timeslots: getDefaultTimeslots(),
    });

    return Promise.resolve();
};

export const createTimeslot = async (slot: Timeslot): Promise<any> => {
    firebaseInit();

    const calendar = await getCalendar();
    const calendarId = calendar.id;

    const slotId = getGUID();
    const slotRef = firebase.database().ref(`data/calendars/${calendarId}/timeslots/${slotId}`);
    await slotRef.set({
        displayName: slot.displayName,
        startTime: slot.startTime.toString(),
        endTime: slot.endTime.toString(),
    });

    return Promise.resolve();
};

export const deleteTimeslot = async (slotId: string): Promise<any> => {
    firebaseInit();

    const calendar = await getCalendar();
    const calendarId = calendar.id;

    const slotRef = firebase.database().ref(`data/calendars/${calendarId}/timeslots/${slotId}`);
    await slotRef.remove();

    return Promise.resolve();
};

export const createMeeting = (meeting: Meeting, calendarId?: string) => {
    firebaseInit();

    const dateKey = getDateKey(meeting.date);
    meeting.meetingId = (!!meeting.meetingId && meeting.meetingId !== '') ? meeting.meetingId : getGUID();

    const {meetingId, date, timeSlot, amountOfTimeslots, hostDisplayName, description, videoLink, displayName} = meeting;

    getCalendarId(calendarId).then((tempCalendarId) => {
        const meetingsRef = firebase.database().ref(`data/calendars/${tempCalendarId}/meetings/${dateKey}/${meetingId}`);
        meetingsRef.set({
            meetingId,
            date: date.toDateString(),
            timeSlot,
            amountOfTimeslots,
            hostDisplayName: hostDisplayName ?? '',
            description,
            videoLink,
            displayName
        });
    });
};

export const editMeeting = async (meeting: Meeting, oldMeeting: Meeting, calendarId?: string): Promise<any> => {
    await deleteMeeting(oldMeeting, calendarId);
    await createMeeting(meeting);
};

export const editMeetingSummary = async (calendarId: string, meeting: Meeting, summary: string): Promise<any> => {
    firebaseInit();

    const dateKey = getDateKey(meeting.date);
    const {meetingId} = meeting;

    const meetingSummaryRef = firebase.database().ref(`data/calendars/${calendarId}/meetings/${dateKey}/${meetingId}/summary`);
    meetingSummaryRef.set(summary).then(() => {
        return Promise.resolve()
    });
};

const getCalendarId = async (calendarId?: string): Promise<string> => {
    if (calendarId) {
        return calendarId;
    }

    const calendar = await getCalendar();
    return calendar.id;
};

export const deleteMeeting = async (meeting: Meeting, calendarId?: string): Promise<any> => {
    firebaseInit();

    let tempCalendarId = await getCalendarId(calendarId);
    const dateKey = getDateKey(meeting.date);
    const {meetingId} = meeting;

    const meetingsRef = firebase.database().ref(`data/calendars/${tempCalendarId}/meetings/${dateKey}/${meetingId}`);
    await meetingsRef.remove();

    return;
};

export const getCalendareShareKey = async (calendarId: string): Promise<string> => {
    firebaseInit();

    const shareKey = getGUID();
    const mappingRef = firebase.database().ref(`data/share/calendars/${shareKey}`);
    mappingRef.set(calendarId).then();

    return shareKey;
};

export const signupToSharedCalendar = async (calendarSharedKey: string): Promise<any> => {
    firebaseInit();

    const userId = getUserId();

    //get calendar real id
    const calendarShareRef = firebase.database().ref(`data/share/calendars/${calendarSharedKey}`);
    const calendarId = await calendarShareRef.once('value').then((snapshot) => snapshot.val());

    if (!calendarId) {
        throw TypeError('no such shared key');
    }

    //add user id to calendar
    const calendarUsersRef = firebase.database().ref(`data/calendars/${calendarId}/general/usersIds`);
    let users = await calendarUsersRef.once('value').then((snapshot) => snapshot.val());

    if (!users) {
        users = [];
    }
    users.push(userId);
    await calendarUsersRef.set(users);

    //add calendar id to user
    const userCalendarsRef = firebase.database().ref(`data/users/${userId}/calendarIds`);
    let userCalendars = await userCalendarsRef.once('value').then((snapshot) => snapshot.val());

    if (!userCalendars) {
        userCalendars = [];
    }
    userCalendars.push(calendarId);
    await userCalendarsRef.set(userCalendars);

    //remove share key
    await calendarShareRef.remove();

    return;
};

export const fetchUserCalendars = async (): Promise<Array<Calendar>> => {
    firebaseInit();

    const userId = getUserId();

    const userRef = firebase.database().ref(`data/users/${userId}`);
    const user: any = await userRef.once('value').then((snapshot) => snapshot.val());

    let calendarIds: Array<string> = [];
    if (user) {
        if (user.calendarIds) {
            calendarIds = Object.assign([], (user.calendarIds as Array<string>));
        }

        calendarIds.push(user.calendarId);
        calendarIds = calendarIds.filter((item, index, self) => self.indexOf(item) === index);
    }

    const calendars: Array<Calendar> = [];

    for (const calendarId of calendarIds) {
        const calendarsRef = firebase.database().ref(`data/calendars/${calendarId}/general`);
        const itemDb = await calendarsRef.once('value').then((snapshot) => snapshot.val());

        if (itemDb) {
            calendars.push({
                displayName: itemDb.displayName,
                id: calendarId as string,
                usersCount: itemDb.usersIds ? (itemDb.usersIds as Array<string>).length : 0,
                isAdmin: true,
            });
        }
    }

    return Promise.resolve(calendars);
};

export const fetchAllCalendarIds = async (): Promise<Array<string>> => {
    firebaseInit();

    const userRef = firebase.database().ref(`data/users`);
    const users: any = await userRef.once('value').then((snapshot) => snapshot.val());

    let calendarIds: {[key:string]: {}} = {};

    for (const userId in users) {
        const user = users[userId];

        if (user.calendarIds) {
            user.calendarIds.forEach((item: string) => {
                calendarIds[item] = {};
            });
        }
    }

    const result: Array<string> = [];

    for (const calendarId in calendarIds) {
        result.push(calendarId);
    }

    return result;
};

export const isCopyLastWeekValid = async (firstDate: Date, calendarId?: string): Promise<boolean> => {
    firebaseInit();

    let tempCalendarId = await getCalendarId(calendarId);

    for (let i = 0; i < 7; i++) {
        const currentDate = moment(firstDate).add(i, 'days').toDate()
        const meetingsRef = firebase.database().ref(`data/calendars/${tempCalendarId}/meetings/${getDateKey(currentDate)}`);
        const itemDb = await meetingsRef.once('value').then((snapshot) => snapshot.val());
        const isEmptyDate = !itemDb || itemDb.length === 0;

        if (!isEmptyDate) {
            return false;
        }
    }

    return true;
};

export const copyLastWeek = async (firstDate: Date, calendarId?: string): Promise<any> => {
    firebaseInit();

    let tempCalendarId = await getCalendarId(calendarId);

    for (let i = 0; i < 7; i++) {
        const currentDate = moment(firstDate).add(i, 'days').toDate()
        const lastWeekDate = moment(currentDate).add(-7, 'days').toDate();

        const lastWeekMeetingsRef = getRefDateMeetings(tempCalendarId, lastWeekDate);
        const meetingsToCopy = await lastWeekMeetingsRef.once('value').then((snapshot) => snapshot.val());

        for (const existingMeetingId in meetingsToCopy) {
            const newMeetingId = getGUID();
            const item = meetingsToCopy[existingMeetingId];

            item['date'] = currentDate.toDateString();
            delete item['summary'];
            item['meetingId'] = newMeetingId;

            const newMeetingRef = getRefMeeting(tempCalendarId, currentDate, newMeetingId);
            newMeetingRef.set(item).then();
        }
    }

    return;
};

export const firebaseLogEvent = (eventName: string, eventLabel?: string, page?: string) => {
  firebaseInit();

  analytics().logEvent(eventName, {event_label: eventLabel ?? '', page_title: page ?? ''});
};

export const useFirebaseSetPage = (pageName: string) => {
    useEffect(() => {
        firebaseInit();

        analytics().setCurrentScreen(pageName);
    }, []);
};